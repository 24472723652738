import { useEffect, useLayoutEffect, useRef } from "react";
import { createUserAccount, devlog } from "@frontend/kitui";
import { isAxiosError } from "axios";

import { makeRequest } from "@api/makeRequest";

import type { UserAccount } from "@frontend/kitui";

export const useUserAccountFetcher = <T = UserAccount>({
  onError,
  onNewUserAccount,
  url,
  userId,
}: {
  url: string;
  userId: string | null;
  onNewUserAccount: (response: T) => void;
  onError?: (error: any) => void;
}) => {
  const onNewUserAccountRef = useRef(onNewUserAccount);
  const onErrorRef = useRef(onError);
  useLayoutEffect(() => {
    onNewUserAccountRef.current = onNewUserAccount;
    onErrorRef.current = onError;
  }, [onError, onNewUserAccount]);
  useEffect(() => {
    if (!userId) return;
    const controller = new AbortController();
    makeRequest<never, T>({
      url,
      contentType: true,
      method: "GET",
      useToken: true,
      withCredentials: false,
      signal: controller.signal,
    })
      .then((result) => {
        devlog("User account", result);
        onNewUserAccountRef.current(result);
      })
      .catch((error) => {
        devlog("Error fetching user account", error);
        if (isAxiosError(error) && error.response?.status === 404) {
          createUserAccount(controller.signal, url.replace("get", "create"))
            .then((result) => {
              devlog("Created user account", result);
              onNewUserAccountRef.current(result as T);
            })
            .catch((error) => {
              if (error.response?.status === 409) return;
              devlog("Error creating user account", error);
              onErrorRef.current?.(error);
            });
        } else {
          onErrorRef.current?.(error);
        }
      });
    return () => controller.abort();
  }, [url, userId]);
};
